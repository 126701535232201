import clsx from 'clsx';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useCallback} from 'react';

import {InputAdornment} from '@mui/material';

import {useStarcarDateRangePickerStateContext} from '../../../../hooks/BPR/useStarcarDateRangePickerState';
import {CustomIcon} from '../../CustomIcon/CustomIcon';
import {FormInputDate} from '../../Form/FormInputDate';
import {TimeAdornment} from '../TimeAdornment';
import styles from './FormInputDate.module.scss';
import {FormInputTime} from '../../Form/FormInputTime';

export const FormInputTimeDepartureMobile = () => {
  const {t} = useTranslation('bpr');
  return (
    <FormInputTimeMobile fieldName={"departureTime"} label={t('pickUpTime')} placeholder={t('pickUpTime')} />
  );
};
export const FormInputTimeArrivalMobile = () => {
  const {t} = useTranslation('bpr');
  return (
    <FormInputTimeMobile fieldName={"arrivalTime"} label={t('returnTime')} placeholder={t('returnTime')} />
  );
};
export const FormInputDateArrival = ({arrivalDisabled}) => {
  const {t} = useTranslation('bpr');
  return (
    <FormInputDateDesktop isFieldDisabled={arrivalDisabled} timeFieldName='arrivalTime' dateFieldName='arrival' placeholder={t('rentalEndPlaceholder')} label={t('rentalEnd')} />
  );
};


export const FormInputDateDeparture = ({departureDisabled}) => {
  const {t} = useTranslation('bpr');
  return (
    <FormInputDateDesktop isFieldDisabled={departureDisabled} timeFieldName='departureTime' dateFieldName='departure' placeholder={t('rentalStartPlaceholder')} label={t('rentalStart')} />
  );
};

export const FormInputTimeMobile = ({fieldName, label, placeholder}: {fieldName: 'departureTime' | 'arrivalTime', label: string, placeholder: string}) => {
  const {setSelectedTimeField, formValues,isWidget, selectedTimeField, setCalendarOpen} =
    useStarcarDateRangePickerStateContext();
  return (
    <FormInputTime
      onClick={() => {
        if (!(!formValues.arrival || !formValues?.departure))
          setSelectedTimeField(fieldName), setCalendarOpen(false);
      }}
      variant={isWidget ? 'standard' : 'filled'}
      name={fieldName}
      label={label}
      placeholder={placeholder}
      disabled={!formValues.arrival || !formValues?.departure}
      className={clsx({[styles.active]: selectedTimeField === fieldName})}
      InputProps={{
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            <CustomIcon
              name="clockAlt"
              style={{
                height: '24px',
                width: '24px',
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};


export const FormInputDateDesktop = ({isFieldDisabled, timeFieldName, dateFieldName, label, placeholder}: {isFieldDisabled: boolean, timeFieldName: 'arrivalTime' | 'departureTime', dateFieldName: 'arrival' | 'departure', label: string, placeholder: string}) => {
  const {
    endFieldProps,
    startFieldProps,
    isWidget,
    formValues,
    openCalendar,
    isDesktop,
    selectedTimeField,
    setSelectedTimeField,
    setCalendarOpen,
    isCalendarOpen,
    isSelecting,
    formMethods,
  } = useStarcarDateRangePickerStateContext();
  const openCalendarCallback = useCallback(
    e => {
      if (
        typeof isFieldDisabled === 'boolean'
          ? !isFieldDisabled
          : !(!formValues.station || !formValues.stationTextField)
      ) {
        openCalendar(e);
      }
    },
    [isFieldDisabled, openCalendar, formValues?.station, formValues?.stationTextField],
  );
  const fieldProps = dateFieldName === 'arrival' ? endFieldProps : startFieldProps;
  return (
    <FormInputDate
      // onClickCapture={openCalendar}
      {...fieldProps}
      className={clsx({
        [styles.active]: (isSelecting && isCalendarOpen && dateFieldName === 'arrival') || (!isSelecting && isCalendarOpen && dateFieldName === 'departure'),
      })}
      sx={{
        width: '100%',
        height: '100%',
        '& .MuiInputBase-adornedStart': {
          paddingRight: 0,
        },
        '& label': {
          lg: {zIndex: !!selectedTimeField ? 1305 : null},
        },
        '& input': {
          lg: {zIndex: !!selectedTimeField ? 1305 : null},
        },
      }}
      variant={isWidget ? 'standard' : 'filled'}
      name={dateFieldName}
      label={label}
      error={formMethods?.formState?.errors?.[dateFieldName] as any}
      placeholder={placeholder}
      disabled={
        typeof isFieldDisabled === 'boolean'
          ? isFieldDisabled
          : !formValues.station || !formValues.stationTextField
      }
      InputProps={
        {
          onClick: openCalendarCallback,
          onKeyDown: (e) => {if (e.key === 'Enter') openCalendarCallback(e)},
          'data-testid': `datepicker-input-${dateFieldName === 'arrival' ? 'end' : 'start'}`,
          readOnly: true,
          startAdornment: (
            <InputAdornment
              sx={{
                lg: {zIndex: !!selectedTimeField ? 1305 : null},
              }}
              onClick={openCalendarCallback}
              position="start"
            >
              <CustomIcon
                name="calendar"
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {!isWidget && isDesktop ? (
                <InputAdornment sx={{marginLeft: 0}} position="end">
                  
                  <TimeAdornment
                    onClick={() => {
                      setSelectedTimeField(timeFieldName);
                      setCalendarOpen(false);
                    }}
                    name={timeFieldName}
                    dateFieldName={dateFieldName}
                  />
                </InputAdornment>
              ) : null}
            </>
          ),
        } as any
      }
    />
  );
};