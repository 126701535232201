import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig() || {};

export const NEXT_PUBLIC_API_URL =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_API_URL
    : publicRuntimeConfig?.NEXT_PUBLIC_API_URL || process.env.NEXT_PUBLIC_API_URL;
export const NEXT_PUBLIC_BASE_URL =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_BASE_URL
    : publicRuntimeConfig?.NEXT_PUBLIC_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL;
export const NEXTAUTH_URL =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXTAUTH_URL
    : publicRuntimeConfig?.NEXTAUTH_URL || process.env.NEXTAUTH_URL;
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;
export const GOOGLE_TAG_MANAGER_ID =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.GOOGLE_TAG_MANAGER_ID
    : publicRuntimeConfig?.GOOGLE_TAG_MANAGER_ID || process.env.GOOGLE_TAG_MANAGER_ID;

export const STATIC_MODE =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.STATIC_MODE === 'true'
      ? true
      : false
    : publicRuntimeConfig?.STATIC_MODE === 'true'
    ? true
    : false || process.env.STATIC_MODE === 'true'
    ? true
    : false;
export const NEXT_TELEMETRY_DISABLED = process.env.NEXT_TELEMETRY_DISABLED;
export const NEXT_PUBLIC_BACKEND_URL =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_BACKEND_URL
    : publicRuntimeConfig?.NEXT_PUBLIC_BACKEND_URL || process.env.NEXT_PUBLIC_BACKEND_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const BUILD_CACHE = process.env.BUILD_CACHE
  ? process.env.BUILD_CACHE === 'true'
    ? true
    : false
  : false;
export const ENABLE_GRID_OVERLAY =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.ENABLE_GRID_OVERLAY === 'true'
      ? true
      : false
    : publicRuntimeConfig?.ENABLE_GRID_OVERLAY === 'true'
    ? true
    : false || process.env.ENABLE_GRID_OVERLAY === 'true'
    ? true
    : false;
export const NEXT_PUBLIC_DEBUG =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_DEBUG === 'true'
      ? true
      : false
    : publicRuntimeConfig?.NEXT_PUBLIC_DEBUG === 'true'
    ? true
    : false || process.env.NEXT_PUBLIC_DEBUG === 'true'
    ? true
    : false;
export const PAGES_REVALIDATE_TIME = process.env.PAGES_REVALIDATE_TIME || 30;

export const TARIFE_PAGES_REVALIDATE_TIME =
  parseInt(process.env.TARIFE_PAGES_REVALIDATE_TIME, 10) || 60 * 15;

export const NEXT_PUBLIC_HCAPTCHA_SITEKEY =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_HCAPTCHA_SITEKEY
    : publicRuntimeConfig?.NEXT_PUBLIC_HCAPTCHA_SITEKEY || process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY;
export const EMERGENCY_MODE =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.EMERGENCY_MODE === '1'
    : publicRuntimeConfig?.EMERGENCY_MODE === '1' || false;
export const NEXT_PUBLIC_NEXT_FRONTEND_WEIGHT =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_NEXT_FRONTEND_WEIGHT
    : publicRuntimeConfig?.NEXT_PUBLIC_NEXT_FRONTEND_WEIGHT ||
      process.env.NEXT_PUBLIC_NEXT_FRONTEND_WEIGHT ||
      '100';
export const NEXT_PUBLIC_COOKIE_BANNER_DELAY =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_COOKIE_BANNER_DELAY
    : publicRuntimeConfig?.NEXT_PUBLIC_COOKIE_BANNER_DELAY ||
      process.env.NEXT_PUBLIC_COOKIE_BANNER_DELAY ||
      0;

export const NEXT_PUBLIC_DISABLE_STATION_IMAGES =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.NEXT_PUBLIC_DISABLE_STATION_IMAGES === 'true'
      ? true
      : false
    : publicRuntimeConfig?.NEXT_PUBLIC_DISABLE_STATION_IMAGES === 'true'
    ? true
    : false || process.env.NEXT_PUBLIC_DISABLE_STATION_IMAGES === 'true'
    ? true
    : false;

export const ADYEN_CLIENT_KEY =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.ADYEN_CLIENT_KEY
    : publicRuntimeConfig?.ADYEN_CLIENT_KEY || process.env.ADYEN_CLIENT_KEY;

export const ADYEN_ENVIRONMENT =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.ADYEN_ENVIRONMENT
    : publicRuntimeConfig?.ADYEN_ENVIRONMENT || process.env.ADYEN_ENVIRONMENT;

export const ADYEN_CLIENT_KEY_PROD =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.ADYEN_CLIENT_KEY_PROD
    : publicRuntimeConfig?.ADYEN_CLIENT_KEY_PROD || process.env.ADYEN_CLIENT_KEY_PROD;

export const ADYEN_ENVIRONMENT_PROD =
  typeof window !== 'undefined' && window?.environment
    ? window?.environment?.ADYEN_ENVIRONMENT_PROD
    : publicRuntimeConfig?.ADYEN_ENVIRONMENT_PROD || process.env.ADYEN_ENVIRONMENT_PROD;
