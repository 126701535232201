import { useEffect } from 'react';


export default function useOutsideAlerter(ref, callback, excludes: string[] = []) {
  /**
   * Alert if clicked on outside of element
   */
  useEffect(() => {
    function handleClickOutside(event) {
      if(event.target.closest(".month-button-disable-events")) {
        event.stopPropagation();
        event.preventDefault();
      }
      

      if (ref && ref.current && !ref.current.contains(event.target)) {
        // Check if the clicked element matches any of the excludes selectors
        const isExcludedElement = excludes.some(selector => {
          return event.target.matches(selector);
        });

        // If the clicked element is not excluded, then trigger the callback
        if (!isExcludedElement) {
          callback();
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
    };
  }, [ref, callback, excludes]);
}